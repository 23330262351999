/*!
 * Start of styles from SB Admin 2 Bootstrap Theme
 */

body {
    background-color: #f8f8f8;
}

#wrapper {
    width: 100%;
}

#page-wrapper {
    padding: 0 15px;
    min-height: 568px;
    background-color: #fff;
}

#prices_list {
    height: 300px;
    overflow: auto;
}

#help-container {
    list-style: none;
    cursor: pointer;
    bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    padding: 11px;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

@media(min-width:768px) {
    #page-wrapper {
        position: inherit;
        margin: 0 0 0 250px;
        padding: 0 30px;
        border-left: 1px solid #e7e7e7;
    }
}

.navbar-top-links {
    margin-right: 0;
}

.navbar-top-links li {
    display: inline-block;
}

.navbar-top-links li:last-child {
    margin-right: 15px;
}

.navbar-top-links li a {
    padding: 15px;
    min-height: 50px;
}

.navbar-top-links li.active a {
    background-color: #eee;
}

.navbar-top-links .dropdown-menu li {
    display: block;
}

.navbar-top-links .dropdown-menu li:last-child {
    margin-right: 0;
}

.navbar-top-links .dropdown-menu li a {
    padding: 10px 20px;
    min-height: 0;
}

.navbar-top-links .dropdown-menu li a div {
    white-space: normal;
}

.dropdown-menu.dropdown-user .divider {
    margin: 0;
}

.navbar-top-links .dropdown-messages,
.navbar-top-links .dropdown-tasks,
.navbar-top-links .dropdown-alerts {
    width: 310px;
    min-width: 0;
}

.navbar-top-links .dropdown-messages {
    margin-left: 5px;
}

.navbar-top-links .dropdown-tasks {
    margin-left: -59px;
}

.navbar-top-links .dropdown-alerts {
    margin-left: -123px;
}

.navbar-top-links .dropdown-user {
    right: 0;
    left: auto;
}

.sidebar .sidebar-nav.navbar-collapse {
    padding-right: 0;
    padding-left: 0;
}

.sidebar .sidebar-search {
    padding: 15px;
}

.sidebar ul li {
    border-bottom: 1px solid #e7e7e7;
}

.sidebar ul li a.active {
    background-color: #eee;
}

.sidebar .arrow {
    float: right;
}

.sidebar .fa.arrow:before {
    content: "\f104";
}

.sidebar .active>a>span>.fa.arrow:before {
    content: "\f107";
}

.sidebar .nav-second-level li,
.sidebar .nav-third-level li {
    border-bottom: 0!important;
}

.sidebar .nav-second-level li a {
    padding-left: 37px;
}

.sidebar .nav-third-level li a {
    padding-left: 52px;
}

@media(min-width:768px) {
    .sidebar {
        z-index: 1;
        position: absolute;
        width: 250px;
        margin-top: 51px;
    }

    .navbar-top-links .dropdown-messages,
    .navbar-top-links .dropdown-tasks,
    .navbar-top-links .dropdown-alerts {
        margin-left: auto;
    }
}

.btn-outline {
    color: inherit;
    background-color: transparent;
    transition: all .5s;
}

.btn-primary.btn-outline {
    color: #428bca;
}

.btn-success.btn-outline {
    color: #5cb85c;
}

.btn-info.btn-outline {
    color: #5bc0de;
}

.btn-warning.btn-outline {
    color: #f0ad4e;
}

.btn-danger.btn-outline {
    color: #d9534f;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
    color: #fff;
}

.chat {
    margin: 0;
    padding: 0;
    list-style: none;
}

.chat li {
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px dotted #999;
}

.chat li.left .chat-body {
    margin-left: 60px;
}

.chat li.right .chat-body {
    margin-right: 60px;
}

.chat li .chat-body p {
    margin: 0;
}

.panel .slidedown .glyphicon,
.chat .glyphicon {
    margin-right: 5px;
}

.chat-panel .panel-body {
    height: 350px;
    overflow-y: scroll;
}

.login-panel {
    margin-top: 25%;
}

.flot-chart {
    display: block;
    height: 400px;
}

.flot-chart-content {
    width: 100%;
    height: 100%;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.428571429;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    border-radius: 25px;
    font-size: 18px;
    line-height: 1.33;
}

.title_detail_sale_container,
.title_detail_purchase_container,
.btn-lg {
    font-size: 16px !important;
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

.show-grid [class^=col-] {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #ddd;
    background-color: #eee!important;
}

.show-grid {
    margin: 15px 0;
}

.huge {
    font-size: 40px;
}

.panel .panel-heading .huge {
    font-size: 30px;
}

.panel-heading.no-left-padding {
    padding-left: 0;
}

.panel-green {
    border-color: #5cb85c;
}

.panel-green .panel-heading {
    border-color: #5cb85c;
    color: #fff;
    background-color: #5cb85c;
}

.panel-green a {
    color: #5cb85c;
}

.panel-green a:hover {
    color: #3d8b3d;
}

.panel-red {
    border-color: #d9534f;
}

.panel-red .panel-heading {
    border-color: #d9534f;
    color: #fff;
    background-color: #d9534f;
}

.panel-red a {
    color: #d9534f;
}

.panel-red a:hover {
    color: #b52b27;
}

.panel-yellow {
    border-color: #f0ad4e;
}

.panel-yellow .panel-heading {
    border-color: #f0ad4e;
    color: #fff;
    background-color: #f0ad4e;
}

.panel-yellow a {
    color: #f0ad4e;
}

.panel-yellow a:hover {
    color: #df8a13;
}

/*!
 * End of styles from SB Admin 2 Bootstrap Theme
 * Custome styles from here on.
 */

.navbar-brand img{
    width:80px;
    float:left;
}

.navbar-brand span{
    float: left;
    font-size: 13px;
    margin: 1px 0 0 8px;
    font-style: italic;
    padding: 1px 6px 0 4px;
    color: #707070;
    line-height: 18px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #e7e7e7;
}

.navbar-brand:hover span{
    color: #fff;
    background-color: #707070;
}

.submit-btn-group .btn {
    padding: 7px 22px;
    margin-left:11px
}

.submit-btn-group hr{
    border: 1px solid #ddd;
    border-style: none none solid;
    margin: 5px 0;
}

.btn-subline {
    font-size: 13px;
    line-height: 1;
    display: block;
}

.text-left {
    text-align: left
}

.form-group.required label:after {
    content:" *";
    color:red;
}

.add-colon:after {
    content:":";
}

/* Remove outline added by chrome*/
*:focus {
    outline: none;
}
/* Remove bootstrap glow on inputs */
input.form-control,input.form-control:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    outline:0px !important;
}

/* Sidebar toggle css styles */
@media(min-width:768px) {
    #wrapper.toggled #sidebar-wrapper .sidebar {
        width: 50px;
    }

    #wrapper.toggled #sidebar-wrapper ul li .masked {
        display: none;
    }

    #wrapper.toggled #sidebar-wrapper ul li ul {
        position: absolute;
        left: 51px;
        margin-top: -41px;
        margin-left: -1px;
        background-color: #f8f8f8;
        width: 200px;
        z-index: 17;
        display: none;
    }
    #wrapper.toggled #sidebar-wrapper ul li:hover > ul,
    #wrapper.toggled #sidebar-wrapper ul li:hover > ul.collapse {
        display: block !important;
        height: auto !important;
        z-index: 1000;
        background: #f8f8f8;
        visibility: visible;
    }

    #wrapper.toggled #sidebar-wrapper ul li ul li{
        border-top: 1px solid #e7e7e7;
        border-right: 1px solid #e7e7e7;
        border-left: 1px solid #e7e7e7;
    }

    #wrapper.toggled #sidebar-wrapper ul li ul li ul {
        position: relative;
        margin-top: 0px;
        margin-left: -51px;
    }

    #wrapper.toggled #sidebar-wrapper .nav-second-level li a {
        padding-left: 15px;
    }

    #wrapper.toggled #page-wrapper {
        margin: 0 0 0 50px;
    }

    #wrapper.toggled #menu-toggle {
        position: fixed;
        bottom: 0;
        width: 50px;
    }

    .scrollable-menu {
        overflow-y: scroll;
        height: 100vh;
        margin-bottom: -101px;
    }

    .scrollable-sub-menu {
        max-height: 400px;
        overflow: hidden;
        overflow-y: auto;
    }

    #menu-toggle {
        position: fixed;
        bottom: 0;
        width: 250px;
        border-bottom: 0;
        border-top: 1px solid #e7e7e7;
        background-color: #f8f8f8;
    }

    #menu-toggle a {
        height: 40px;
    }

    #menu-toggle a i{
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3px;
    }
}
@media(max-width:767px) {
    #menu-toggle {
        display: none;
    }
}

.input-min-width {
    min-width:60px;
}

.sidebar ul li a i, .navbar-top-links li a i {
    color: #707070;
}

@media (min-width:992px) {
    .col-right-padding-desktop {
        padding-right: 15px !important;
    }
    .col-no-right-padding-desktop {
        padding-right: 0px !important;
    }
    .col-no-left-padding-desktop {
        padding-left: 0px !important;
    }
}

/* Twitter Typeahead styles */
span.twitter-typeahead .tt-menu,
span.twitter-typeahead .tt-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
}
span.twitter-typeahead .tt-suggestion {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333333;
    white-space: nowrap;
    cursor: pointer;
}
span.twitter-typeahead .tt-suggestion.tt-cursor,
span.twitter-typeahead .tt-suggestion:hover,
span.twitter-typeahead .tt-suggestion:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    background-color: #337ab7;
}
.input-group.input-group-lg span.twitter-typeahead .form-control {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
}
.input-group.input-group-sm span.twitter-typeahead .form-control {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}
span.twitter-typeahead {
    width: 100%;
}
.input-group span.twitter-typeahead {
    display: block !important;
    height: 34px;
}
.input-group span.twitter-typeahead .tt-menu,
.input-group span.twitter-typeahead .tt-dropdown-menu {
    top: 32px !important;
}
.input-group span.twitter-typeahead:not(:first-child):not(:last-child) .form-control {
    border-radius: 0;
}
.input-group span.twitter-typeahead:first-child .form-control {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group span.twitter-typeahead:last-child .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.input-group.input-group-sm span.twitter-typeahead {
    height: 30px;
}
.input-group.input-group-sm span.twitter-typeahead .tt-menu,
.input-group.input-group-sm span.twitter-typeahead .tt-dropdown-menu {
    top: 30px !important;
}
.input-group.input-group-lg span.twitter-typeahead {
    height: 46px;
}
.input-group.input-group-lg span.twitter-typeahead .tt-menu,
.input-group.input-group-lg span.twitter-typeahead .tt-dropdown-menu {
    top: 46px !important;
}
.tt-hint {
    color: #999
}
.tt-no-matches {
    margin:6px 20px;
}
/* End of Twitter Typeahead styles */

/* Row with equal height columns */
.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
}

/* For centering columns inside a row when there are less than 12 columns */
.row-centered {
    text-align:center;
}
.col-centered {
    display:inline-block;
    float:none;
    /* reset the text-align */
    text-align:left;
    /* inline-block space fix */
    margin-right:-4px;
}
/* End column centering styles */

.soft-right-border {
    border-right: 1px solid #ddd;
}

.strong-top-border td {
    border-top: 1px solid #000 !important;
}

.strong-top-border-dashed td {
    border-top: 1px dashed #000 !important;
}


/* CSS shortcuts */
.fixed-width-27 {
    width: 27px;
}
.fixed-width-90 {
    width: 90px;
}
.fixed-width-100 {
    width: 100px;
}
.fixed-width-110 {
    width: 110px;
}
.fixed-width-120 {
    width: 120px;
}
.fixed-width-150 {
    width: 150px;
}
.margin-bottom-20 {
    margin-bottom: 20px;
}
.margin-bottom-10 {
    margin-bottom: 10px;
}
.margin-top-minus-3 {
    margin-top: -3px;
}
.margin-top-25 {
    margin-top: 25px;
}
.margin-top-20 {
    margin-top: 20px;
}
.margin-top-15 {
    margin-top: 15px;
}
.margin-top-10 {
    margin-top: 10px;
}
.margin-top-5 {
    margin-top: 5px;
}
.margin-left-5 {
    margin-left: 5px;
}
.margin-right-10 {
    margin-right: 10px;
}
.margin-right-20 {
    margin-right: 20px;
}
.margin-right-10 {
    margin-right: 10px;
}
.no-bottom-margin {
    margin-bottom: 0;
}
.no-top-margin {
    margin-top: 0;
}
.no-margins {
    margin: 0;
}
.no-right-border {
    border-right: 0;
}
.min-height-30 {
    min-height: 30px;
}
.min-height-20 {
    min-height: 20px;
}
.col-no-padding {
    padding: 0px;
}
.col-no-padding-left {
    padding-left: 0px;
}
.col-no-padding-right {
    padding-right: 0px;
}
.vertical-align-col {
    display: flex;
    align-items: center;
    justify-content: center; /* Optional, to align inner items horizontally inside the column */
}

.inline-flex {
    display: inline-flex;
}
/* End CSS shortcuts */

.input-big-text {
    font-size: 23px;
}

.green {
    color: #5cb85c;
}

.red {
    color: #dc4a4a;
}

a.editable-value, a.editable-value:hover {
    text-decoration: none;
    border-bottom: dashed 1px;
    padding: 0px 3px;
}

.input-inline {
    height: 20px;
    padding: 0px 2px;
    line-height: 1.5;
    border-radius: 3px;
}

select.small {
    font-size: 12px;
}

tr.no-top-border td {
    border-top: 0px !important;
}
.medium-text {
    font-size: 18px;
}
.panel-alt {
    background-color: #f8f8f8;
    border-color: #e7e7e7;
}

.table-striped-alt tr:nth-child(even) {
    background: #f1f1f1;
}

/* Vue Transitions */
.pos-alert-transition {
    transition: all .4s ease-in-out;
    overflow: hidden;
    opacity: 1;
}

.pos-alert-enter, .pos-alert-leave {
    opacity: 0;
}
.payment-error {
    margin: 2px;
}
.sale-items-table tr td {
    padding: 0px 8px !important;
}

.sale-items-table tr td.item-quantity {
    width: 40px;
    padding-right: 0 !important;
}

.dots-tooltip-link {
    display: inline-block;
    height: 12px;
    padding: 0 5px 5px;
    font-size: 12px;
    font-weight: bold;
    line-height: 6px;
    color: #555;
    text-decoration: none;
    vertical-align: middle;
    background: #ddd;
    border-radius: 1px;
    letter-spacing: 1px;
    margin-left: 5px;
}

.dots-tooltip-link:hover,
.dots-tooltip-link:active,
.dots-tooltip-link:focus {
    background: #d0d0d0;
    color: #555;
    text-decoration: none;
    outline: 0;
}

.export-buttons {
    text-align: right;
    margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
    .export-buttons {
        text-align: center;
        margin: 10px 0 0 0;
    }

    .export-buttons .dt-buttons{
        width: inherit !important;
    }
}

.outlet-selection-help .popover {
    width: 300px;
}

.popover-200px .popover {
    width: 200px;
}

.popover-250px .popover {
    width: 250px;
}

.popover-230px .popover {
    width: 230px;
}

.popover-150px .popover {
    width: 150px;
}


.td-left-border {
    border-left: 2px solid #ddd;
}

.regular-line-height {
    line-height: 1;
}

@media (min-width: 768px) {
    .modal .modal-xl {
        width: 80%;
        max-width:1200px;
    }
}
.hide-delete-btn .delete_record_btn {
    display:none;
}
.hide-checked-btn .btn-success{
    display:none;
}
.hide-unchecked-btn .btn-default{
    display:none;
}

.list-group-condensed .list-group-item {
    padding: 5px 10px;
}
.list-group-item-alt {
    background-color: #f8f8f8;
}

.label-cell {
    white-space: nowrap;
    width: 1%;
    background-color: #f8f8f8;
}

.details-table > thead > tr > td,
.details-table > tbody > tr > td,
.details-table > tfoot > tr > td {
    padding-left: 8px;
    padding-right: 8px;
}

div.dataTables_wrapper div.dataTables_processing {
    top: 40%;
}

.price {
    font-size:1.5em;
    font-weight:600;
}
.price sup {
    font-size: .7em;
    font-weight: 200;
    color: #5d5d5d;
    top: -.2em;
}

.icon-outlet {
    background-image: url("../images/outlet-icon.png");
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
}
.icon-1x {
    height: 14px;
    width: 18px;
    margin-bottom: -1px;
}
.icon-3x {
    height:43px;
    width:43px;
}
.icon-5x {
    height:70px;
    width:70px;
}

button.mercadopago-button {
    color: #fff;
    background-color: #5cb85c;
    border: 1px solid #4cae4c;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;

    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
}

form.mercadopago-form {
    display: inline-block;
}

.modal-backdrop.dark-in {
    filter: alpha(opacity=80);
    opacity: .8;
}

.subscription-cta-logo {
    width: 200px;
    margin: 0 0 20px 0;
}

.subscription-cta-modal {
    margin: 20px;
}

/* DIRTY Responsive pricing table CSS */

pricing {
    width:100%;
    max-width:1000px;
    margin:0 auto;
    height:1000px;
    position:relative;
}
pricing table { border-collapse:collapse; table-layout:fixed; width:100%; margin: 0 auto; }
pricing th { background:#F5F5F5; cursor: pointer}
pricing td, pricing th {
    height:53px;
    width: auto;
    border:1px solid #DDD; padding:10px; empty-cells:show;
    text-align:center;
    display:table-cell !important;
}
.bg-green {
    border-top:3px solid #0bbb4f;
}
.bg-gray {
    border-top:3px solid #b8b8b8;
}
.sep {
    background:#F5F5F5;
    font-weight:bold;
}
.price {
    font-size:1.2em;
    font-weight:600;
    color: #0bbb4f;
}
.price sup {
    font-size: .7em;
    font-weight: 200;
    color: #5d5d5d;
    top: -.2em;
}

.disable-interaction {
    pointer-events: none;
    opacity: 0.4;
}

/* Referred page */
.description-referred {
    text-align: justify;
    text-justify: inter-word;
}

.referred-img img {
    width: 100%;
}

.title-form-referred {
    margin-bottom: 20px;
}
/* -----------------------------------------
   Dropdown
----------------------------------------- */
.dropdown-menu{
    padding:0 0 0 0;
}
a.dropdown-menu-header {
    background: #f7f9fe;
    font-weight: bold;
    border-bottom: 1px solid #e3e3e3;
}
.dropdown-menu > li a {
    padding: 10px 20px;
}

.drop-down-context-btn {
	background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
    border-right: 0;
    color: #555;
}

/* -----------------------------------------
   Badge
----------------------------------------- */
.badge {
    padding: 3px 5px 2px;
    position: absolute;
    top: 8px;
    right: 20px;
    display: inline-block;
    min-width: 10px;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    line-height: 1;
    vertical-align: baseline;
    white-space: nowrap;
    text-align: center;
    border-radius: 10px;
}

.badge-danger {
    background-color: #db5565;
}

.clickeable-body {
    cursor: pointer;
}

.mark-as-seen-container {
    font-size: 12px;
    padding: 0 20px;
    min-height: 0;
    cursor: pointer;
}

.inactiveLink {
     pointer-events: none;
     cursor: default;
 }

/* Notifications sections */
.footer_container_notification {
    background-color: #f5f5f5;
}
.notifiaction_separator {
    margin-bottom: 0px;
}
/* -----------------------------------------
   dashboard
----------------------------------------- */

.reports .main-numbers .row {
    margin-bottom: 20px;
}

.reports .main-numbers .row .amount-money {
    color: #3c763d;
    font-weight: 500;
    font-size: 25px;
}

.reports .main-numbers .row .help {
    padding-top: 5px;
}

.reports .main-numbers .card-header {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
}

.reports .main-numbers .selection-dates .text-danger {
    padding-top: 10px;
}

.reports .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    border: 1px solid rgba(70,95,145,0.1);
    background-clip: border-box;
    border-radius: 3px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.reports .card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.form-control input {
    border: none;
    box-sizing: border-box;
    outline: 0;
    padding: .75rem;
    position: relative;
    width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.main-numbers .period-detail, #charge_order_btn_group {
    display: flex;
}

.main-numbers label {
    padding-right: 7px;
}

.main-numbers .radio-buttons-compare-container {
    padding-top: 10px;
}

.invalid-input {
    border: 2px solid red;
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
    top: 13px;
}

/** Account css **/
.th_total_separator {
    border-top: 1px;
    border-top: 1px dotted;
    border-top: medium dashed grey;
}

/* remove bootstrap-select "Select all" btn */
select[data-max-options="1"] ~ .dropdown-menu .bs-actionsbox .bs-select-all {
    display: none;
}
select[data-max-options="1"] ~ .dropdown-menu .bs-actionsbox .bs-deselect-all {
    width: 100%;
}

/* -----------------------------------------
   Breadcrumbs
----------------------------------------- */
.has_breadcrumbs .page-header {
    margin: 0px 0 20px;
}

.has_breadcrumbs .breadcrumb_container {
    margin-bottom: 0;
    padding: 4px 15px;
    margin-top: 10px;
}

